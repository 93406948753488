import setCookie from "~/components/directory/utilities/setCookie";
import { getCookie } from "~/components/directory/utilities/getCookie";
import loadingUI from "~/components/directory/utilities/loadingUI";

export async function getGeoLocationStatus() {
  const [lat, lng] = currentCookies();
  const geolocationTimeStamp = getCookie('geolocation_timestamp');
  // check if the coors as well as the timestamp are present
  // the timestamp acts as a way to make sure that the user location stays fresh
  if (!isNaN(lat) && !isNaN(lng) && geolocationTimeStamp !== null && geolocationTimeStamp !== undefined) {
    return 'fresh';
  } else if (await hasGeolocationEnabled()) {
    return updateLocationState();
  }
  return 'unavailable';
}

export async function onGeolocationGranted(callback) {
  if ('geolocation' in navigator) {
    const result = await navigator.permissions.query({name: 'geolocation'});
    result.addEventListener('change', (e) => {
      if (e.target.state === 'granted') {
        return callback();
      }
    });
  }
}


export async function setUpGeolocation() {
  const status = await getGeoLocationStatus();
  if (status === 'stale') {
    reloadPage();
  }
  return status;
}

function reloadPage() {
  loadingUI.start('Getting clinics nearest to you');
  window.location.href = `${window.location.pathname}?geo=1`;
}

async function hasGeolocationEnabled() {
  if ('geolocation' in navigator) {
    const result = await navigator.permissions.query({name: 'geolocation'})
    return result.state !== 'denied';
  }
  return false;
}

async function updateLocationState() {
  const previousLocation = currentCookies();
  setGeolocationTimeStamp();
  try {
    const currentLocation = await getCurrentLocation();
    if (hasPositionChanged(previousLocation, currentLocation)) {
      setPositionCookies(...currentLocation);
      return 'stale';
    }
    return 'fresh';

  } catch {
    return 'unavailable';
  }
}

// the geolocation expiry exists for the browser to check the user location after a certain length of time
// this is in case the user location changes
// it uses the current time as a placeholder value, but is not used.
function setGeolocationTimeStamp() {
  setCookie('geolocation_timestamp', new Date(), 7);
}

function setPositionCookies(lat, lng) {
  if(lat !== null && lng !== null) {
    setCookie('lat', lat, 365);
    setCookie('lng', lng, 365);
  }
}

function currentCookies() {
  const lat = getCookie('lat');
  const lng = getCookie('lng');
  return [lat, lng].map(coor => parseFloat(coor))
}

async function getCurrentLocation() {
  const extractCoords = (position) => ([position.coords.latitude, position.coords.longitude]);
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => resolve(extractCoords(position)),
      err => reject(err),
    );
  });
}

function hasPositionChanged(oldVal, newVal) {
  const [oldLat, oldLng] = oldVal;
  const [newLat, newLng] = newVal;

  if(isNaN(oldLng) || isNaN(oldLat)){
    return true;
  }

  const latChanged = Math.abs(oldLat - newLat) > 0.001
  const lngChanged = Math.abs(oldLng - newLng) > 0.001

  return latChanged || lngChanged;
}



